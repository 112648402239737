// 系统管理相关请求
import request from '@/request/request'
// 请求树形数据的接口
export const MenuTreeApi = () => request.get('/admin/sys/menu/tree');

// 新增菜单接口
export const MenuAddApi = (params) => request.post('/admin/sys/menu/add', params);
// 菜单列表接口 
export const MenuListApi = () => request.get('/admin/sys/menu/tree/copy');
// 删除菜单接口
export const MenuDeleteApi = (params) => request.post(`/admin/sys/menu/delete/${params.id}`, params);
// 更新菜单接口
export const MenuUpdateApi = (params) => request.post(`/admin/sys/menu/update/${params.id}`, params);
// 菜单详情接口
export const MenuDetailApi = (params) => request.get(`/admin/sys/menu/detail/${params.id}`, { params });


// 问题列表搜索查询
export const LogExceptionListApi = (params) => request.post(`/admin/log/exception/list`, params);
// 问题详情
export const GetExceptionLogApi = (params) => request.get(`/admin/log/exception/getExceptionLog`, { params });
// 问题初始化
export const SaveBatchErrorLogApi = (params) => request.get(`/admin/log/exception/saveBatchErrorLog`, { params });
// 删除所有异常日志
export const ExceptionDellAllApi = (params) => request.get(`/admin/log/exception/dellAll`, { params });


// 获取操作日志
export const GetOperationsApi = (params) => request.post(`/admin/log/exception/getOperations`, params);
// 获取操作日志详情
export const GetOperationInfoApi = (params) => request.post(`/admin/log/exception/getOperationInfo/${params.id}`, params);


// 全局常量列表 java
export const GetConstantResultsApi = (params) => request.get(`/admin/config/getConstantResults`, { params });
// 修改全局常量
export const UpdConstantResultApi = (params) => request.get(`/admin/config/updConstantResult`, { params });


// 全局常量列表 go
export const getConstantResultsV2Api = (params) => request.post(`/adminv2/config/getConstantResults`,  params);
// 修改全局常量
export const editConstantApi = (params) => request.post(`/adminv2/config/editConstant`, params)
// 添加全局常量
export const addConstantApi = (params) => request.post(`/adminv2/config/addConstant`, params)
// 刷新缓存
export const flushCacheAPI = (params) => request.post(`/adminv2/config/flushCache`, params)
